<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA COLABORADORES
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i class="fas fa-user-friends fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaColaboradores.length}}</span>
                            <br>
                            <span class="text-muted h6">Colaboradores</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de colaboradores </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalNuevoColaborador = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo colaborador
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Colaboradores registrados</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaColaboradores" :fields="campoColaboradores" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(importeComision)="data">
                                        {{ data.value}}%
                                    </template>
                                    <template #cell(acciones)="param">
                                        <b-button @click="abrirModalModificarColaborador(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-button>
                                        <b-button @click="eliminarColaborador(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>

                        <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalNuevoColaborador">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-plus-square fa-md"></i>
                                    <span class="h5"> Nuevo colaborador </span>
                                </h6>
                                <CButtonClose @click="modalNuevoColaborador = false" class="text-white" />
                            </template>

                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(nuevoColaborador)">
                                    <b-row>
                                        <b-col md="12">
                                            <validation-provider name="colaborador" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Colaborador:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese nombre de colaborador" v-model="datosNuevoColaborador.nombres"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="importe de comisión" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Importe de comisión:" class="mb-2">
                                                    <b-form-input type="number"  step=".01" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese importe de comisión" v-model="datosNuevoColaborador.importeComision"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button size="md" variant="danger" @click="modalNuevoColaborador = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>

                        <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalModificarColaborador">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-edit fa-md"></i>
                                    <span class="h5"> Actualizar cliente </span>
                                </h6>
                                <CButtonClose @click="modalModificarColaborador = false" class="text-white" />
                            </template>
                            <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(modificarColaborador)">
                                    <b-row>
                                        <b-col md="12">
                                            <validation-provider name="colaborador" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Colaborador:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese nombre de colaborador" v-model="datosModificarColaborador.nombres"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="importe de comisión" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Importe de comisión:" class="mb-2">
                                                    <b-form-input type="number" step="0.1" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese importe de comisión" v-model="datosModificarColaborador.importeComision"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button size="md" variant="danger" @click="modalModificarColaborador = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalNuevoColaborador: false,
            modalModificarColaborador: false,
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoColaboradores: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombres",
                    label: "Colaborador",
                    class: "text-center",
                },
                {
                    key: "importeComision",
                    label: "Importe comisión (%)",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaColaboradores: [],
            datosNuevoColaborador: {
                idColaborador: '',
                nombres: '',
                importeComision: 0
            },
            datosModificarColaborador: {
                idColaborador: '',
                nombres: '',
                importeComision: 0
            },
            disabled: false,
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        nuevoColaborador() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-colaborador",
                    me.datosNuevoColaborador, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalNuevoColaborador = false;
                    me.listarColaboradores();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        modificarColaborador() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-colaborador",
                    me.datosModificarColaborador, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalModificarColaborador = false
                    me.listarColaboradores();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalNuevoColaborador() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosNuevoColaborador.nombres = '',
                this.datosNuevoColaborador.importeComision = 0
        },
        resetModalModificarColaborador() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
        },
        abrirModalModificarColaborador(param) {
            let me = this;
            me.datosModificarColaborador.idColaborador = param.item.idColaborador;
            me.datosModificarColaborador.nombres = param.item.nombres;
            me.datosModificarColaborador.importeComision = param.item.importeComision;
            me.modalModificarColaborador = true;

        },
        listarColaboradores() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-colaboradores")
                .then(function (response) {
                    me.listaColaboradores = response.data;
                    me.totalRows = me.listaColaboradores.length;
                    //me.obtenerDatosEstadisticos();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarColaborador(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar al colaborador?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "master/eliminar-colaborador", {
                            idColaborador: param.item.idColaborador
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarColaboradores();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalNuevoColaborador: function (val) {
            if (val == false) {
                this.resetModalNuevoColaborador();
            }
        },
        modalModificarColaborador: function (val) {
            if (val == false) {
                this.resetModalModificarColaborador();
            }
        }
    },
    mounted() {
        let me = this;
        me.listarColaboradores();
    }

}
</script>
